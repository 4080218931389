@import 'assets/style/main.css';

body {
    margin: 0;
    font-family: "Alef", "Helvetica Neue", sans-serif !important;
    direction: rtl;
    height: 100dvh;
    margin-top: -16px;
}

#root {
    margin: 0;
    height: 100dvh;
    margin-top: 16px;
}

.p-component {
    font-family: "Alef", "Helvetica Neue", sans-serif !important;

}

::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    outline: 1px solid slategrey;
    border-radius: 200rem;
    height: 1rem;
    width: 1rem;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.p-confirm-dialog-message {
    margin: 0 !important;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    99% {
        opacity: 0.99;
        width: 100%;
        height: 100%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    99% {
        opacity: 0.99;
        width: 100%;
        height: 100%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    99% {
        opacity: 0.01;
        width: 100%;
        height: 100%;
    }

    100% {
        opacity: 1;
        width: 0;
        height: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    99% {
        opacity: 0.01;
        width: 100%;
        height: 100%;
    }

    100% {
        opacity: 1;
        width: 0;
        height: 0;
    }
}

.p-dialog-footer {
    .p-button {
        &:enabled:hover {
            background: #f1f1f1;
            color: black;
            border-color: black;
        }
        &:enabled:active {
            background: #535353;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .p-confirm-dialog-reject {
        color: #f1f1f1;
        background: #000;
        box-shadow: none;
    }
    .p-confirm-dialog-accept {
        color: #000;
        box-shadow: none;
    }
}

.p-button,
.p-button.p-button-outlined,
button {
    box-shadow: -3px 3px #000;
    border-radius: 6px;
    border: 1px solid #000;
    background: white;
    color: #000;

    &:enabled:hover {
        background: #f1f1f1;
        color: black;
        border-color: black;
    }
    &:enabled:active {
        background: #535353;
    }
    &:focus {
        box-shadow: none;
    }
    justify-content: center;
}

.p-inputtext,
input {
    box-shadow: -3px 3px #c1c1c1;
    border-radius: 6px;
    border: 1px solid #a1a1a1;

    &:hover:active {
        border: 1px solid #a1a1a1;
        box-shadow: -3px 3px #c1c1c1;
    }
    &:enabled:focus {
        border: 1px solid #000;
        box-shadow: 0 0 #c1c1c1;

    }

}

/* Overwrite elements 
Containers / Cards

-webkit-box-shadow: -5px 5px 0 1px #000;
box-shadow: -5px 5px 0 1px #000;
border: 1.5px solid #000;
border-radius: 6px;

Buttons 

box-shadow: -3px 3px #000;
border-radius: 6px;
border: 1px solid #000;
*/